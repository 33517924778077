import React from "react"
import { Jumbotron, Card } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n/config"
import { useTranslation } from "react-i18next"

const Help = () => {
  const data = useStaticQuery(graphql`
    query {
      queue: file(relativePath: { eq: "QUEUEPos.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      service1: file(relativePath: { eq: "service1.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      service2: file(relativePath: { eq: "service2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      service3: file(relativePath: { eq: "service3.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      service4: file(relativePath: { eq: "service4.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      service5: file(relativePath: { eq: "LINE2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const imageStyles = {
    height: 100,
    width: 100,
  }

  const listStyles = {
    fontSize: 25,
  }
  const { t } = useTranslation()
  return (
    <Jumbotron>
      <div className="container">
        <div className="row" style={{ justifyContent: "center", margin: 20 }}>
          <h2 style={{ paddingTop: 30 }}>{t("service-1")}</h2>
        </div>
        <hr />
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <Img
            fluid={data.service1.childImageSharp.fluid}
            style={imageStyles}
          />
        </div>
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <h3 style={{ fontWeight: "bold" }}>{t("service-2")}</h3>
        </div>
        <ul style={listStyles}>
          <li>• {t("service-3")}</li>
          <li>• {t("service-4")}</li>
          <li>• {t("service-5")}</li>
          <li>• {t("service-6")}</li>
        </ul>
        <hr />
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <Img
            fluid={data.service2.childImageSharp.fluid}
            style={imageStyles}
          />
        </div>
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <h3 style={{ fontWeight: "bold" }}>{t("service-7")}</h3>
        </div>
        <ul style={listStyles}>
          <li>• {t("service-8")}</li>
          <li>• {t("service-9")}</li>
        </ul>
        <hr />
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <Img
            fluid={data.service3.childImageSharp.fluid}
            style={imageStyles}
          />
        </div>
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <h3 style={{ fontWeight: "bold" }}>{t("service-10")}</h3>
        </div>
        <ul style={listStyles}>
          <li>• {t("service-11")}</li>
          <li>• {t("service-12")}</li>
        </ul>
        <hr />
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <Img
            fluid={data.service4.childImageSharp.fluid}
            style={imageStyles}
          />
        </div>
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <h3 style={{ fontWeight: "bold" }}>{t("service-13")}</h3>
        </div>
        <ul style={listStyles}>
          <li>• {t("service-14")}</li>
          <li>• {t("service-15")}</li>
          <li>• {t("service-16")}</li>
          <li>• {t("service-17")}</li>
        </ul>
        <hr />
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <h3>{t("service-18")}</h3>
        </div>
        <div className="row" style={{ justifyContent: "center", margin: 10 }}>
          <a href="https://line.me/R/ti/p/@kws2842e">
            <Img
              fluid={data.service5.childImageSharp.fluid}
              style={imageStyles}
            />
          </a>
        </div>
      </div>
    </Jumbotron>
  )
}

export default Help
